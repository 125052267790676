import styled from 'styled-components'
import {
  Text,
  Flex,
  Heading,
  IconButton,
  // ArrowBackIcon,
  NotificationDot,
  ChevronLeftIcon,
  Button,
  Box,
  useMatchBreakpoints,
} from 'packages/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Link from 'next/link'
// import { isMobile } from 'react-device-detect'
import { useTranslation } from 'contexts/Localization'
import { useRouter } from 'next/router'
// import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string | any
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  flex-direction: column;
`

const StyledHeading = styled(Heading)`
  font-weight: 500;

  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 20px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 32px;
  }
`

const StyledButton = styled(Button)`
  box-shadow: none;
  height: 42px;
  background: transparent;
  border-radius: 100px;
  width: 100%;
  font-size: 14px;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 20px;
  }
`

const Tabs = styled(Flex)`
  background: var(--icon-button-color);
  padding: 2px;
  border-radius: 100px;
  margin-bottom: 24px;
`

const FlexTop = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xs} {
    flex-direction: unset;
    padding: 16px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 24px;
  }
`

const Hr = styled.div`
  border: 1px solid #999999;
  width: 80%;
  margin: auto;
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  const [expertMode] = useExpertModeManager()
  const { isMobile } = useMatchBreakpoints()

  const router = useRouter()
  const { t } = useTranslation()

  const hRef = router.asPath

  return (
    <AppHeaderContainer>
      <FlexTop>
        <Flex width="100%" alignItems="center" mr={noConfig ? 0 : '16px'}>
          {backTo && (
            <Box style={{ transform: 'translateY(2px)' }}>
              <Link passHref href={backTo}>
                <IconButton as="a">
                  <ChevronLeftIcon color="black" width="30px" mr="10px" />
                </IconButton>
              </Link>
            </Box>
          )}
          <Flex width="100%" flexDirection="column">
            <StyledHeading as="h2">{title}</StyledHeading>

            <Flex alignItems="center">
              {helper && <QuestionHelper color="white" text={helper} mr="4px" placement="top-start" />}
              <Text color="black" textAlign="left" fontSize="14px">
                {subtitle}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {!noConfig && (
          <Flex alignItems="center">
            <NotificationDot show={expertMode}>
              <GlobalSettings color="white" mr="0" />
            </NotificationDot>
            {/* <Transactions /> */}
          </Flex>
        )}
      </FlexTop>

      {title === 'Liquidity' || title === 'Import Pool' ? (
        <Box padding={['0 16px 16px', , , , '0 24px 24px']}>
          <Tabs alignItems="center">
            <Link href="/liquidity" passHref>
              <StyledButton
                style={{
                  // border: hRef === '/liquidity' ? '1px solid #7A72AF' : 'none',
                  background: hRef === '/liquidity' && 'var(--button-color)',
                }}
                id="join-pool-button"
              >
                {isMobile ? t('Add LP') : t('Add Liquidity')}
              </StyledButton>
            </Link>

            <Link href="/find" passHref>
              <StyledButton
                style={{
                  // border: hRef === '/find' ? '1px solid #7A72AF' : 'none',
                  background: hRef === '/find' && 'var(--button-color)',
                }}
                id="import-pool-link"
              >
                {t('Import Pool')}
              </StyledButton>
            </Link>
          </Tabs>

          <Box height="1px" background="#bebebe" />
        </Box>
      ) : null}

      {hRef === 'Liquidity' || hRef === 'find' ? <Hr /> : null}
    </AppHeaderContainer>
  )
}

export default AppHeader
