import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none" viewBox="0 0 11 7">
      <path fill="#fff" d="M9.086 0c.89 0 1.337 1.077.707 1.707L5.25 6.25.707 1.707C.077 1.077.523 0 1.414 0h7.672z" />
    </Svg>
  )
}

export default Icon
