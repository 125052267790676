import React from 'react'
import { useTranslation } from 'contexts/Localization'
import { Flex } from '../Box'
import StyledToggle, { Input, Handle } from './StyledToggle'
import { ToggleProps, scales } from './types'
import { Text } from '../Text'

const Toggle: React.FC<ToggleProps> = ({
  checked,
  defaultColor = 'input',
  checkedColor = 'success',
  scale = scales.LG,
  startIcon,
  endIcon,
  ...props
}) => {
  const { t } = useTranslation()

  const isChecked = !!checked

  return (
    <>
      <Flex style={{ gap: '10px' }}>
        <StyledToggle checked={isChecked} checkedColor={checkedColor} defaultColor={defaultColor} scale={scale}>
          <Input checked={checked} scale={scale} {...props} type="checkbox" />
          {startIcon && endIcon ? (
            <>
              <Handle scale={scale}>
                <Flex height="100%" alignItems="center" justifyContent="center">
                  {checked ? endIcon(checked) : startIcon(!checked)}
                </Flex>
              </Handle>
              <Flex width="100%" height="100%" justifyContent="space-around" alignItems="center">
                {startIcon()}
                {endIcon()}
              </Flex>
            </>
          ) : (
            <>
              <Handle scale={scale}>
                <Text fontSize="16px" fontWeight={checked ? '700' : '400'} color={checked ? 'black' : 'white'}>
                  {t('On')}
                </Text>
              </Handle>
            </>
          )}
        </StyledToggle>

        <StyledToggle checked={!isChecked} checkedColor={checkedColor} defaultColor={defaultColor} scale={scale}>
          <Input checked={checked} scale={scale} {...props} type="checkbox" />
          {startIcon && endIcon ? (
            <>
              <Handle scale={scale}>
                <Flex height="100%" alignItems="center" justifyContent="center">
                  {checked ? endIcon(checked) : startIcon(!checked)}
                </Flex>
              </Handle>
              <Flex width="100%" height="100%" justifyContent="space-around" alignItems="center">
                {startIcon()}
                {endIcon()}
              </Flex>
            </>
          ) : (
            <>
              <Handle scale={scale}>
                <Text fontSize="16px" fontWeight={checked ? '400' : '700'} color={checked ? 'white' : 'black'}>
                  {t('Off')}
                </Text>
              </Handle>
            </>
          )}
        </StyledToggle>
      </Flex>
    </>
  )
}

export default Toggle
