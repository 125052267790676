import styled from 'styled-components'
import { Card } from 'packages/uikit'

export const BodyWrapper = styled(Card)`
  z-index: 1;
  background: var(--primary-color);
  box-shadow: var(--modal-box-shadow);
  border-radius: 20px;
  /* box-shadow: inset 0px 0px 50px 10px rgba(77, 68, 135, 0.8); */
  width: 320px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 533px;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
